import { PATH } from '@/constants/path';
import { Link } from '@shopify/polaris';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ContactFormStyled } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import widgetSlice, { dataSelector, errorSelector } from '@/redux/slice/widget.slice';
const ContactForm = ({ handleSetStep }: { handleSetStep?: (step: number) => void }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttonGroup = useSelector(dataSelector);
  const error = useSelector(errorSelector);
  return (
    <ContactFormStyled>
      {buttonGroup.form ? (
        <>
          To access to form preview, please navigate to{' '}
          <Link
            onClick={() => {
              navigate(PATH.CONTACT_FORM);
            }}
          >
            Form settings
          </Link>
        </>
      ) : (
        <>
          To preview your form, please create or add a form first. Go to
          <Link
            onClick={() => {
              handleSetStep?.(1);
              dispatch(
                widgetSlice.actions.handleError({
                  ...error,
                  status: true,
                  field: 'form',
                }),
              );
            }}
          >
            Configuration
          </Link>{' '}
          to add your form
        </>
      )}
    </ContactFormStyled>
  );
};

export default memo(ContactForm);
